var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.param.vendorFlag
        ? [
            _c("c-tab", {
              attrs: {
                type: "vertical",
                tabItems: _vm.tabItems,
                height: _vm.height,
              },
              on: {
                "update:height": function ($event) {
                  _vm.height = $event
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (tab) {
                      return [
                        _c(tab.component, {
                          key: tab.key,
                          tag: "component",
                          attrs: {
                            riskReduce: _vm.riskReduce,
                            param: _vm.param,
                            height: _vm.height,
                          },
                          on: {
                            "update:riskReduce": function ($event) {
                              _vm.riskReduce = $event
                            },
                            "update:risk-reduce": function ($event) {
                              _vm.riskReduce = $event
                            },
                            research: _vm.research,
                          },
                        }),
                      ]
                    },
                  },
                ],
                null,
                false,
                3567275168
              ),
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            }),
          ]
        : [
            _c(_vm.imprComponent, {
              tag: "component",
              attrs: {
                riskReduce: _vm.riskReduce,
                param: _vm.param,
                height: _vm.height,
              },
              on: {
                "update:riskReduce": function ($event) {
                  _vm.riskReduce = $event
                },
                "update:risk-reduce": function ($event) {
                  _vm.riskReduce = $event
                },
                research: _vm.research,
              },
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }